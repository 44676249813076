
import { Options, prop, Vue } from "vue-class-component";
import Calendar from "primevue/calendar";

// REFERENCE https://primefaces.org/primevue/showcase/#/calendar
class Props {
  dateFormat = prop<string>({
    default: "dd MM yy",
    type: String
  });
  isError = prop<boolean>({
    default: false,
    type: Boolean
  });
  disabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  showDatePicker = prop<boolean>({
    default: true,
    type: Boolean
  });
  showTimePicker = prop<boolean>({
    default: false,
    type: Boolean
  });
  modelValue = prop<string>({
    required: true
  });
  modelValueTime = prop<string>({
    default: "",
    type: String
  });
  stepHour = prop<number>({
    default: 1,
    type: Number
  });
  stepMinute = prop<number>({
    default: 1,
    type: Number
  });
  minDate = prop<string | Date>({
    // minDate	Date	null	The minimum selectable date. Ex: MinDate = new Date ()
    default: null,
    type: [Date, String]
  });
  maxDate = prop<string | Date>({
    // maxDate	Date	null	The maximum selectable date. Ex: MaxDate = new Date()
    default: null,
    type: [Date, String]
  });
  disabledDays = prop<Array<any>>({
    // Ex: :disabledDays="[0,6]" (index-> 0:minggu)
    default: null,
    type: Array
  });
  disabledDates = prop<Array<any>>({
    // disabledDates="[today,invalidDate]" today=new Date()
    default: null,
    type: Array
  });
  yearNavigator = prop<boolean>({
    default: true,
    type: Boolean
  });
  yearRange = prop<string>({
    // yearRange	string	null	The range of years displayed in the year drop-down in (nnnn:nnnn) format such as (2000:2020).
    default: "2010:2040",
    type: String
  });
  manualInput = prop<boolean>({
    default: false,
    type: Boolean
  });
  placeholder = prop<string>({
    type: String,
    default: "Pilih tanggal"
  });
  showIcon = prop<boolean>({
    default: true,
    type: Boolean
  });
  height = prop<number>({
    default: 2.435,
    type: Number
  });
  customClass = prop<string>({
    default: "mb-2",
    type: String
  });
  customClassTime = prop<string>({
    default: "",
    type: String
  });
  setDefaultDate = prop<boolean>({
    default: false,
    type: Boolean
  });
  showChevron = prop<boolean>({
    default: true,
    type: Boolean
  });
  showButtonBar = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  emits: [
    "selected",
    "selected-time",
    "update:modelValue",
    "update:isError",
    "update:modelValueTime"
  ],
  components: {
    Calendar
  },
  watch: {
    modelValue() {
      this.$emit("update:isError", !this.modelValue);
    }
  }
})
export default class LpDatePickerV2 extends Vue.with(Props) {
  timeValue: any = "";
  chevronUp = false;
  onDateSelected(value: Date) {
    this.$emit("update:modelValue", value);
    this.$emit("selected", value);
  }
  onTimeSelected(value: Date) {
    this.$emit("update:modelValueTime", value);
    this.$emit("selected-time", value);
  }
  created() {
    if (this.setDefaultDate && this.modelValue) {
      this.timeValue = new Date(this.modelValue);
      return;
    }
    this.timeValue = this.modelValue;
  }
  timeFormat(time: string): string {
    return time
      .replace(/[^0-9:]+/, "")
      .replace(/(\d{2})(\d{2})/g, "$1:$2")
      .slice(0, 5);
  }
  onInput(event: any) {
    event.target.value = this.timeFormat(event.target.value);
    const time = event.target.value.split(":");
    const hours = time[0];
    const minutes = time[1] || "0";

    if (time.length === 2 && hours.length === 2 && minutes.length === 2) {
      const val = this.modelValue
        ? new Date(
            new Date(this.modelValue).setHours(Number(hours), Number(minutes))
          )
        : new Date(new Date().setHours(Number(hours), Number(minutes)));

      this.timeValue = val;
      this.$emit("update:modelValue", val);
      this.$emit("selected", val);
    }
  }
  onBlur(event: any) {
    event.target.value = this.timeFormat(event.target.value);
    const time = event.target.value.split(":");
    const hours = time[0];
    const minutes = time[1] || "0";

    if (
      hours.length !== 2 ||
      Number(hours) >= 24 ||
      minutes.length !== 2 ||
      Number(minutes) > 60
    ) {
      const defaultTime = new Date(
        new Date().setHours(Number(hours), Number(minutes))
      );

      this.timeValue = defaultTime;
      this.$emit("update:modelValue", defaultTime);
      this.$emit("selected", defaultTime);
    }
  }
  onClearDate() {
    this.$emit("clear", null);
  }
}
